import React, { useEffect, useState } from 'react';
import {
  Box, Button, CardActions, Dialog, DialogTitle, Divider, Typography,
} from '@mui/material';
import {
  FileOpenOutlined, QuestionAnswerOutlined, SchoolOutlined,
} from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import mixpanel from 'mixpanel-browser';
import { colors } from '../../theme';
import OutlinedIconBadge from './OutlinedIconBadge';
import { GET_HUB_PET_PARENT } from '../../queries';

const infoCards = [
  {
    icon: <QuestionAnswerOutlined />,
    title: '30-days of access to trainers via text message and email',
  }, {
    icon: <FileOpenOutlined />,
    title: 'Copies of your pet’s documents',
  }, {
    icon: <SchoolOutlined />,
    title: 'Learning hub access',
  },
];

function OnboardingDialog() {
  const [open, setOpen] = useState(false);

  const { data, loading } = useQuery(GET_HUB_PET_PARENT);

  useEffect(() => {
    if (!loading && data) {
      const hasSeenModal = data.petParent.numHubLogins > 1 || localStorage.getItem('hasSeenOnboardingModal');
      if (hasSeenModal) return;

      if (data.petParent.startedDaysAgo <= 7) {
        setOpen(true);
        mixpanel.track('welcome_page_view');
      }
    }
  }, [loading, data]);

  if (loading) return null;

  const hasDogs = data?.petParent?.animals?.find((a) => a.animalType === 'Dog');

  const handleClose = () => {
    localStorage.setItem('hasSeenOnboardingModal', 'true');
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '600px',
          maxWidth: 'calc(100vw - 32px)',
          margin: '16px',
        },
      }}
    >
      <DialogTitle sx={{ px: 3, pt: 3 }}>
        <Typography color={colors.primary.dark} sx={{ lineHeight: '26.5px' }} variant="sectionTitle">{`Hi ${data?.petParent?.firstName}, Welcome to Petcademy`}</Typography>
      </DialogTitle>
      <Divider />
      <Box sx={{ px: 3 }}>
        <Typography sx={{ py: 2 }} variant="body1">
          {`We heard you adopted a ${hasDogs ? 'dog' : 'cat'} from ${data?.petParent?.awo?.name} 🎉🥳 The team at Petcademy is here to support you with:`}
        </Typography>
        <Divider />
        {infoCards.map((card) => (
          <>
            <Box sx={{ mx: -2 }}>
              <OutlinedIconBadge icon={card.icon} title={card.title} />
            </Box>
            <Divider />
          </>
        ))}
        <Typography variant="body2" sx={{ py: 2 }}>
          {`Welcome (or ${hasDogs ? 'woof' : 'meow'}) from your new friends at Petcademy!
          We're thrilled to be part of your pet parent journey.`}
        </Typography>
      </Box>
      <Divider />
      <CardActions sx={{ justifyContent: 'end' }}>
        <Button
          variant="contained"
          onClick={() => {
            mixpanel.track(
              'start_sniffing_click',
              undefined,
              () => handleClose(),
            );
          }}
        >
          start sniffing around
        </Button>
      </CardActions>
    </Dialog>
  );
}

export default OnboardingDialog;
