import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import mixpanel from 'mixpanel-browser';
import { Box, CircularProgress } from '@mui/material';
import { GET_TYPEFORM_PET_PARENT } from '../../queries';

const spinner = (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}
  >
    <CircularProgress />
  </Box>
);

function Donate() {
  const [showSpinner, setShowSpinner] = useState(true);
  const { loading, data } = useQuery(GET_TYPEFORM_PET_PARENT);
  useEffect(() => {
    if (data?.petParent.awo.donationLink) {
      setTimeout(() => {
        window.location.replace(data?.petParent.awo.donationLink);
        setShowSpinner(false);
      }, 2000);
    }
  }, [data]);
  if (loading || showSpinner) return spinner;
  return null;
}

export default Donate;
